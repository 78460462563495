<div class="container">
  <div class="privatePolicy">
    <div class="row">
      <div class="col-3 d-lg-inline d-none">
        <div class="header-menu">
          <p>{{translate.data.CommonQuestions.commonQuestions}}</p>
        </div>
        <div class="body-menu">
          <ul>
            <li><p [class]="first" (click) = "onActive('first')">{{translate.data.CommonQuestions.generalQuestions}}</p></li>
            <li><p [class]="second" (click) = "onActive('second')">{{translate.data.CommonQuestions.paymentMethods}}</p></li>
            <li><p [class]="third" (click) = "onActive('third')">{{translate.data.CommonQuestions.cancelReserve}}</p></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-9 col-12">
        <div class="header-content">
          <p class="d-lg-inline d-none">{{translate.data.CommonQuestions.title}}</p>
          <p class="d-lg-none d-inline">الأسئلة الشائعة</p>
        </div>
        <div class="body-content">

          <div class="d-lg-none d-inline mobile-menu">
            <ul>
              <li><p [class]="first" (click)="onActive('first')">أسئلة عامة</p></li>
              <li><p [class]="second" (click) = "onActive('second')">طرق الدفع</p></li>
              <li><p [class]="third" (click) = "onActive('third')">الغا الحجز</p></li>
            </ul>
          </div>

          <p class="active mb-3 d-lg-none d-inline">{{generalQuestions?.questionType}}</p>

          <div *ngIf="first">
            <p class="title">{{generalQuestions ? generalQuestions[translate.data.requestdData.genQuastion] : '' }}</p>
            <p class="answer">{{generalQuestions ? generalQuestions[translate.data.requestdData.genAnswer] : '' }}</p>
          </div>

          <div *ngIf="second">
            <p class="title">{{paymentMethods ? generalQuestions[translate.data.requestdData.genQuastion] : '' }}</p>
            <p class="answer">{{paymentMethods? generalQuestions[translate.data.requestdData.genAnswer] : '' }}</p>
          </div>

          <div *ngIf="third">
            <p class="title">{{removeReverse? generalQuestions[translate.data.requestdData.genQuastion] : ''}}</p>
            <p class="answer">{{removeReverse? generalQuestions[translate.data.requestdData.genAnswer] : '' }}</p>
          </div>

          <div class="Pagination">
            <img class="right" src="../../../assets/Pagination/Group 10357.svg" alt="">
            <img class="left" src="../../../assets/Pagination/Group 10358.svg" alt="">
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
